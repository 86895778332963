import DemoSiteRequest from "../../javascript/react/components/demo_site_request/DemoSiteRequest";
import Dropzone  from "../../javascript/react/components/dropzone/DropZone";
import Editor from "../../javascript/react/components/editor/Editor";
import reactRender from "./shared/react_render";

const components = {
    DemoSiteRequest, Dropzone, Editor
};

reactRender(components)
