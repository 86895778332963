import React from 'react';
import {useDropzone} from 'react-dropzone';


const onDrop = async (acceptedFiles) => {
    console.log(acceptedFiles);
};

export default function Basic() {

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({onDrop});

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));
    React.useEffect(() => {
        if (acceptedFiles.length > 0) {
            const md5 = getMd5(acceptedFiles[0])

        }
    })


    return (
        <section className="container">
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <aside>
                <h4>Files</h4>
                <ul>{files}</ul>
            </aside>
        </section>
    );
}
